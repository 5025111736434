<template>
  <CCard style="margin-top: 20px">
    <CCardHeader class="bg-yellow text-black"><strong>รายวิชา/กิจกรรม:<br />
        {{ $store.state.selectedSubject.label }}</strong>
    </CCardHeader>

    <CCardBody>
      <form id="formCreateLog" v-on:submit.prevent="createLog" method="POST">
        <CRow>
          <!--body-->

          <CCol lg="12">
            <RdoSubjectType :type="'basic-search'" />
          </Ccol>
          <CCol lg="6">
            <SltLevel />
          </CCol>
          <CCol lg="6">
            <SltClass />
          </CCol>

          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                วันที่
              </label>
              <datepicker name="txtDate" input-class="form-control bg-white" :required="true" format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม" v-model="txtDate"></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ช่องทางการสอน:
              </label>
              <select name="sltRoute" class="form-control" required v-if="routeInfo" v-model="selectedRoute">
                <option value="">ไม่ระบุ</option>
                <option v-for="route in routeInfo" :key="route.id" :value="route.route_id">
                  {{ route.route_name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12">
            <ChkPeriod :modalType="'create'" />
          </CCol>
          <CCol lg="12">
            <ContentStdInput />
          </CCol>








          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="
                block
                uppercase
                text-gray-700 text-xs
                font-bold
                mb-2
              " htmlFor="grid-password">
                นักเรียนทั้งหมด (คน)
              </label>
              <input type="number" name="numStdAmount" class="form-control" required
                v-model="$store.state.totalStudent" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="alert alert-info">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                  นักเรียนที่เข้าเรียน (คน)
                </label>
                <div class="h1 text-right">
                  {{ attendedStudent }} <span v-if="$store.state.totalStudent > 0"> ({{
          Number(
            (attendedStudent / $store.state.totalStudent) * 100
          ).toLocaleString()
        }}
                    %)</span>
                </div>
              </div>
            </div>
          </CCol>

          <CCol lg="12" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ผลการจัดการเรียนการสอน ปัญหา และข้อเสนอแนะ:
              </label>
              <textarea class="form-control" name="problemDetail" rows="3"></textarea>
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                <strong>ภาพประกอบรายงาน:</strong>
              </label>

              <UploadImages @changed="handleImages" :max="1" maxError="อัพโหลดได้เพียง 1 ภาพ เท่านั้น"
                uploadMsg="คลิ๊กเพื่อเลือกภาพ" />
            </div>
          </CCol>
          <CCol lg="12" class="text-right">
            <button type="submit" class="btn btn-yellow">บันทึก</button>
          </CCol>
        </CRow>
      </form>
    </CCardBody>


    <!-------------------MODAL CREATE--------------------------------------------------------------------------------------->

    <!-------------------MODAL EDIT--------------------------------------------------------------------------------------->

    <!-------------------MODAL SEARCH FOR PRINT--------------------------------------------------------------------------------------->
    <form id="formSearchForPrint" v-on:submit.prevent="searchForPrint" method="POST">
      <CModal :show.sync="modalPrint" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="warning">
        <CRow>
          <!--body-->

          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                จากวันที่
              </label>
              <datepicker name="dateStart" input-class="form-control bg-white" :required="true" format="yyyy-MM-dd"
                placeholder="" v-model="selectedStartDate"></datepicker>
            </div>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                ถึงวันที่
              </label>
              <datepicker name="dateEnd" input-class="form-control bg-white" :required="true" format="yyyy-MM-dd"
                placeholder="" v-model="selectedEndDate"></datepicker>
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน
              </label>
              <input type="number" name="schoolCode" class="form-control" readonly v-model="userData.user_address" />
            </div>
          </CCol>
          <CCol lg="12" class="text-right">
            <button type="submit" class="btn btn-yellow">Search</button>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">ปริ้นบันทึกการสอน:</h3>
          </h6>
          <CButtonClose @click="modalPrint = false" class="text-white" />
        </template>

        <template #footer>
          ----
          <!--
          <CButton @click="modalPrint = false" color="danger">Discard</CButton>

          -->
        </template>
      </CModal>
    </form>


  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./components/SltSubject.vue";
import SltStudent from "./components/SltStudent.vue";
import SltStudent1 from "./components/SltStudent1.vue";
import SltStudent2 from "./components/SltStudent2.vue";
import SltLevel from "./components/SltLevel.vue";
import SltClass from "./components/SltClass.vue";
import TblAttendanceByDateTeacher from "./components/TblAttendanceByDateTeacher.vue";
import ChkPeriod from "./components/ChkPeriod.vue";
import RdoSubjectType from "./components/RdoSubjectType";
import UploadImages from "vue-upload-drop-images";
import arrayEqual from "array-equal";
import { CFormCheck } from "@coreui/vue";
import { CRadio } from '@coreui/vue';
import RdoSubjectTypeVue from './components/RdoSubjectType.vue';
import ContentStdInput from './components/ContentStdInput.vue';

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    SltStudent1,
    SltStudent2,
    SltLevel,
    SltClass,
    ChkPeriod,
    UploadImages,
    TblAttendanceByDateTeacher,
    RdoSubjectType,
    ContentStdInput,
    CFormCheck,
    CRadio
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "teaching_log_id",
          type: "number",
        },
        {
          label: "วันที่สอน",
          field: "teaching_date",
        },
        {
          label: "รายวิชา",
          field: "subject_name",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
        },
        {
          label: "ห้อง/ กลุ่ม",
          field: "teaching_std_class",
          type: "number",
        },
        {
          label: "จำนวน (คน)",
          field: "teaching_std_amount",
          type: "number",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      modalUploadPic: false,
      modalPrint: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      handyAttachments: [],
      teachingLogInfo: null,

      myFiles: [],
      selectedFiles: null,
      routeInfo: null,
      btnControl: { saving: false, saving2: false },
      currentSemester: null,
      uploadPercentage: 0,
      txtDate: new Date(),
      uploadControl: [0, 0, 0],
      selectedRoute: 2,
      activeTabIndex: 0,
      radioNames: ['Radios', 'Inline Radios',
        'Radios - custom', 'Inline Radios - custom']
    };
  },
  mounted() {

    //FIND CURRENT SEMESTER
    let formSemester = new FormData();
    formSemester.append("txtAction", "get");
    formSemester.append("qSemester", "all");
    this.axios
      .post(this.$hostUrl + "php_action/semesterAPI.php", formSemester, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log(response.data);
        this.currentSemester = response.data.mainData[0].code;
        //console.log(this.currentSemester);
      });

    //FETCH ROUTE DROP DOWN
    let formData = new FormData();
    formData.append("txtAction", "getAllRoute");
    this.axios
      .post(this.$hostUrl + "php_action/routeAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.routeInfo = response.data.mainData;
      });
    //FETCH SUBJECT INFO
    let formSubject = new FormData();
    formSubject.append("txtAction", "getSubjectData");
    formSubject.append("subjectId", this.$route.params.subjectId);

    this.axios
      .post(this.$hostUrl + "php_action/subjectAPI.php", formSubject)
      .then((response) => {
        //console.log(response.data);
        this.$store.commit("setSelectedSubject", response.data.mainData);
        this.$store.commit("setCheckedPeriod", [0]);
      });



  },
  watch: {},
  computed: {
    attendedStudent() {
      let amountSelected = this.$store.state.selectedStudent.length
        ? this.$store.state.selectedStudent.length
        : 0;
      let amountSelected1 = this.$store.state.selectedStudent1.length
        ? this.$store.state.selectedStudent1.length
        : 0;
      let amountSelected2 = this.$store.state.selectedStudent2.length
        ? this.$store.state.selectedStudent2.length
        : 0;
      return (
        this.$store.state.totalStudent - amountSelected - amountSelected1 - amountSelected2
      );
    },
  },
  methods: {
    handleTabChange(index) {
      this.activeTabIndex = index;
    },
    openModal(appId) {
      this.modalCreate = true;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
          "php_action/appointmentAPI.php?txtAction=getSingleAppointment&appId=" +
          appId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedAppointment = response.data.mainData;
        });

      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        });
    },
    createLog(event) {
      if (this.$store.state.checkedPeriod.length == 0) {
        alert("กรุณาระบุคาบเรียนที่สอน");
        return false;
      }
      let formId = event.target.id;
      this.postTeachingLog(formId);
      //this.postMissStd(formId);
      //this.postLeaveStd(formId)


      ;
      //this.postCovidStd(formId);
    },
    checkSuccess() {
      //console.log(this.uploadControl);
      if (arrayEqual(this.uploadControl, [1, 1, 1])) {
        //location.reload();
        return false;
      }
    },
    postCovidStd(formId) {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent2.length > 0) {
        const form = document.getElementById(formId);
        let formMiss = new FormData(form);
        formMiss.append("txtAction", "create");
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append(
          "selectedSubject",
          this.$store.state.selectedSubject.code
        );
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent2)
        );
        formMiss.append("sltHours", this.$store.state.checkedPeriod.length);
        formMiss.append("txtReason", 246);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append("secNo", this.$store.state.selectedClass);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน Covid สำเร็จ");
              this.uploadControl[3] = 1;
              this.checkSuccess();
            } else {
              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[3] = 1;
      }
      this.checkSuccess();
    },
    postLeaveStd(formId) {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent1.length > 0) {
        const form = document.getElementById(formId);
        let formMiss = new FormData(form);
        formMiss.append("txtAction", "create");
        formMiss.append("userId", this.userData.user_id);
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append(
          "selectedSubject",
          this.$store.state.selectedSubject.code
        );
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent1)
        );
        formMiss.append("sltHours", this.$store.state.checkedPeriod.length);
        formMiss.append("txtReason", 61);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append("secNo", this.$store.state.selectedClass);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน ลา สำเร็จ");
              this.uploadControl[2] = 1;
              this.checkSuccess();
            } else {
              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[2] = 1;
      }
      this.checkSuccess();
    },
    postMissStd(formId) {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent.length > 0) {
        const form = document.getElementById(formId);
        let formMiss = new FormData(form);
        formMiss.append("txtAction", "create");
        formMiss.append("userId", this.userData.user_id);
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append(
          "selectedSubject",
          this.$store.state.selectedSubject.code
        );
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent)
        );
        formMiss.append("sltHours", this.$store.state.checkedPeriod.length);
        formMiss.append("txtReason", 245);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append("secNo", this.$store.state.selectedClass);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน ขาดเรียน สำเร็จ");
              this.uploadControl[1] = 1;
              this.checkSuccess();
            } else {
              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[1] = 1;
      }
      this.checkSuccess();
    },
    postFleeStd(formId) {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent2.length > 0) {
        const form = document.getElementById(formId);
        let formMiss = new FormData(form);
        formMiss.append("txtAction", "create");
        formMiss.append("userId", this.userData.user_id);
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append(
          "selectedSubject",
          this.$store.state.selectedSubject.code
        );
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent2)
        );
        formMiss.append("sltHours", this.$store.state.checkedPeriod.length);
        formMiss.append("txtReason", 4);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append("secNo", this.$store.state.selectedClass);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน หนีเรียน สำเร็จ");
              this.uploadControl[1] = 1;
              this.checkSuccess();
            } else {

              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[1] = 1;
      }
      this.checkSuccess();
    },
    postTeachingLog(formId) {
      //SEND DATA
      if (!this.$store.state.selectedSubject) {
        alert("กรุณาเลือกรายวิชา");
        return false;
      }
      if (this.$store.state.selectedLevel.length == 0) {
        alert("กรุณาเลือกระดับชั้นที่สอน");
        return false;
      }
      if (this.$store.state.totalStudent == 0) {
        alert("กรุณาระบุจำนวนนักเรียน");
        return false;
      }

      this.btnControl.saving = true;
      const form = document.getElementById(formId);
      let formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("userId", this.userData.user_id);
      formData.append("levelCode", this.$store.state.selectedLevel.code);
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append(
        "period",
        JSON.stringify(this.$store.state.checkedPeriod)
      );
      formData.append("missStd", this.$store.state.selectedStudent.length);
      formData.append("leaveStd", this.$store.state.selectedStudent1.length);
      formData.append("numStdAttended", this.attendedStudent);
      formData.append("covidStd", this.$store.state.selectedStudent2.length);
      if (this.selectedFiles) {
        formData.append("file", this.selectedFiles[0]);
      }
      formData.append("sltClass", this.$store.state.selectedClass);


      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "created") {
            this.uploadControl[0] = 1;
            alert("บันทึกการสอนสำเร็จ");
            this.checkSuccess();
            Promise.all([this.postMissStd(formId), this.postLeaveStd(formId), this.postFleeStd(formId)]).then(values => {
              //alert("ประมวลผลเรียบร้อย");
              console.log(values);
              if (this.uploadControl[0] == 1) {
                this.$router.push({ name: "รายงานการสอน" });
              }

            });
          } else {
            //alert(response.data.error);
            console.log("finished");
            this.btnControl.saving = false;
          }
          this.checkSuccess();
        });
    },

    removeLog(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("userId", this.userData.user_id);
        formData.append("logId", value);
        this.axios
          .post(this.$hostUrl + "php_action/teachingAPI.php", formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.updateState == "updated") {
              alert("บันทึกสำเร็จ");
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          });
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    searchForPrint(event) {
      if (this.selectedStartDate == null || this.selectedEndDate == null) {
        this.msgAlert = "ต้องเลือกทั้งวันเริ่มต้น และวันสิ้นสุดของการค้นหา";
        this.modalAlert = true;
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getTeachingLogByDate");
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.teachingLogInfo = response.data.mainData;
        });
    },
    openModalEdit(data) {
      this.modalEdit = true;
      let formData = new FormData();
      formData.append("txtAction", "getTeachingLogByID");
      formData.append("logId", data);
      formData.append("schoolCode", this.userData.user_address);

      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData)
        .then((response) => {
          //console.log(response.data);
          let jsonSubject = {
            code: response.data.mainData.teaching_subject,
            label:
              response.data.mainData.subject_code +
              " " +
              response.data.mainData.subject_name,
          };
          let jsonPeriod = JSON.parse(response.data.mainData.teaching_periods);
          this.$store.commit("setSelectedTeachingLog", response.data.mainData);
          this.$store.commit("setSelectedSubject", jsonSubject);
          this.$store.commit("setCheckedPeriod", jsonPeriod);
          this.$store.commit("setSelectedStudent", "");
          this.$store.commit("setSelectedStudent1", "");
          this.$store.commit(
            "setSelectedLevel",
            response.data.mainData.levelData
          );
          this.$store.commit(
            "setSelectedClass",
            response.data.mainData.teaching_std_class
          );
          console.log(this.$store.state.selectedTeachingLog);
        });
    },
    updateLog(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("level", this.$store.state.selectedLevel.code);
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append(
        "period",
        JSON.stringify(this.$store.state.checkedPeriod)
      );
      formData.append("missStd", this.$store.state.selectedStudent.length);
      formData.append(
        "oldPicLink",
        this.$store.state.selectedTeachingLog.teaching_pictures
      );
      if (this.selectedFiles) {
        formData.append("file", this.selectedFiles[0]);
      }
      formData.append("sltClass", this.$store.state.selectedClass);
      formData.append("leaveStd", this.$store.state.selectedStudent1.length);
      formData.append("covidStd", this.$store.state.selectedStudent2.length);

      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => { });
    },
    uploadFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uppoaded");
            this.pictUrl1 = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uppoaded");
            this.pictUrl2 = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uppoaded");
            this.pictUrl3 = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uppoaded");
            this.pictUrl4 = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uppoaded");
            this.fileLink1 = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uppoaded");
            this.fileLink2 = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uppoaded");
            this.fileLink3 = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uppoaded");
            this.fileLink4 = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => { });
    },
    editFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uploaded");
            this.pic1New = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uploaded");
            this.pic2New = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uploaded");
            this.pic3New = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uploaded");
            this.pic4New = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uploaded");
            this.file1New = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uploaded");
            this.file2New = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uploaded");
            this.file3New = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uploaded");
            this.file4New = this.$hostFileUrl + response.data.message.file8Url;
          }
        });
    },
    handleImages(files) {
      this.selectedFiles = files;
      console.log(this.selectedFiles[0]);
    },



    updateTotalStudentClass() {
      console.log(this.$store.state.studentList.length);
      this.$store.commit("setTotalStudent", this.$store.state.studentList.length);
    },
    updateTotalStudentSec() {
      console.log(this.$store.state.studentListSec.length);
      this.$store.commit("setTotalStudent", this.$store.state.studentListSec.length);
    }

  },
};
</script>
<style scoped></style>
