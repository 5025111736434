import { render, staticRenderFns } from "./CreateTeachingLog.vue?vue&type=template&id=49bd2ba5&scoped=true&"
import script from "./CreateTeachingLog.vue?vue&type=script&lang=js&"
export * from "./CreateTeachingLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49bd2ba5",
  null
  
)

export default component.exports