<template>
  <div>
    <!--
    <SltLevel/>
    -->
    <div v-if="studentInfo" style="margin-top: 5px">
      <v-select
        multiple
        :options="studentInfo"
        v-model="$store.state.selectedStudent2"
        @input="mutateSelectedStudent"
      >
        <template #search="{ attributes, events }">
          <input
            class="from-control ui-input-text"
            v-bind="attributes"
            v-on="events"
          />
        </template>
      </v-select>
    </div>

    <div v-else>
      <div class="spinner-border text-info" role="status">
        <span class="sr-only"></span>
      </div>
      Loading...
    </div>
<!--
    <div class="text-right">
      <CButton color="light" @click="showModal = true">filter:</CButton>
    </div>
    -->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SltLevel from "./SltLevel.vue";
export default {
  components: {
    Datepicker,
    vSelect,
    SltLevel
  },
  data() {
    return {
      subjectInfo: null,
      studentInfo: null,
      levelInfo:null,
      userData: this.$cookies.get("user"),
      selectedSubject: null,
      selectedStudent: null,
      selectedCiteria: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {


    //FETCH ALL STUDENT DROPDOWN
    if (this.userData.user_address) {
      var schoolCode = this.userData.user_address;
    } else {
      schoolCode = this.userData.school_code;
    }
    this.axios
      .get(
        this.$hostUrl +
          "php_action/userAPI.php?txtAction=get&qStd=all&schoolId=" +
          schoolCode
      )
      .then((response) => {
        //console.log(response.data);
        //this.$store.commit("setStudentDropdown", response.data.mainData);
        this.studentInfo = response.data.mainData;
      })
      .finally(() => {});
  },
  methods: {
    mutateSelectedStudent(value) {
      this.$store.commit("setSelectedStudent2", value);
    },

    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
